<template>
  <Page ref="page" v-loading="loading">
    <div style="margin-bottom: 10px">
      <el-button type="primary" size="small" @click="$utils.openAddShop()">
        新增门店
      </el-button>
      <el-button type="primary" size="small" @click="$utils.openAddFaultUser()">
        新增报障人
      </el-button>
      <el-button type="primary" size="small" @click="$utils.openUseTemplate()">
        {{ templateData.name ? '修改模板' : '使用模板' }}
      </el-button>
      <span v-if="templateData.name">{{ templateData.name }}</span>

      &emsp;
      <el-button
        v-if="templateData.name"
        type="text"
        size="small"
        @click="cancel"
      >
        取消使用模板
      </el-button>
    </div>
    <el-form ref="form" label-width="0px">
      <el-table :data="orders" border>
        <el-table-column header-align="center" width="90">
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <i
                v-if="$index > 0"
                style="color: #f56c6c; font-size: 24px; margin-right: 10px"
                class="el-icon-delete"
                @click="del($index)"
              ></i>
              <el-tooltip
                class="item"
                effect="dark"
                content="复制本行数据"
                placement="right"
              >
                <i
                  style="font-size: 24px"
                  class="el-icon-circle-plus-outline"
                  @click="copyRow($index, { ...row })"
                ></i>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>
              <span class="red-star">*</span>
              分公司
            </div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="" :rules="rules.branchOfficeName">
              <SelectSearch
                searchKey="branchOfficeName"
                :searchFn="getBranchOfficeList"
                :value.sync="orders[$index].branchOfficeName"
                @change="
                  data => {
                    branchOfficeChange(data, $index)
                  }
                "
                :validFn="query => !!query"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>
              <span class="red-star">*</span>
              门店
            </div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="" :rules="rules.storeName">
              <SelectSearch
                :ref="`SelectSearchStore${$index}`"
                searchKey="storeName"
                :searchFn="getStoreNameList"
                :value.sync="orders[$index].storeName"
                :searchParams="{
                  branchOfficeId: orders[$index].branchOfficeId
                }"
                needFocus
                @change="
                  data => {
                    storeChange(data, $index)
                  }
                "
                :validFn="
                  (query, searchParams) =>
                    !!(searchParams.branchOfficeId || query)
                "
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="storeCode"
          label="门店编码"
          width="180"
        ></el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>
              <span class="red-star">*</span>
              报障人
            </div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="" :rules="rules.userName">
              <SelectSearch
                searchKey="userName"
                labelKey="nickname"
                :searchFn="getUserList"
                :value.sync="orders[$index].userName"
                @change="data => (orders[$index].userId = data.id)"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>
              <span class="red-star">*</span>
              报障类型
            </div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item
              label=""
              :prop="'orders.' + $index + '.code'"
              :rules="rules.workOrderType"
            >
              <el-select
                v-model="orders[$index].workOrderType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in [
                    { value: '0', label: '售后' },
                    { value: '1', label: '运维' }
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header" slot-scope="{ row, $index }">
            <div>
              <!-- <span
                v-if="row.workOrderType && row.workOrderType == 0"
                class="red-star"
              >
                *
              </span> -->
              序列号
              <el-tooltip
                class="item"
                effect="dark"
                content="“报障类型”为“售后”时，要必填序列号，否则无法报障"
                placement="right"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <SelectSearch
                searchKey="sn"
                labelKey="id"
                :searchFn="getFianSn"
                :value.sync="orders[$index].code"
                clearable
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>序列号关单必填</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <el-switch
                v-model="orders[$index].codeRequired"
                active-text="开"
                active-value="1"
                inactive-value="0"
                inactive-text="关"
              ></el-switch>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>
              <span class="red-star">*</span>
              故障类型
            </div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item
              label=""
              :prop="'orders.' + $index + '.code'"
              :rules="rules.faultName"
            >
              <SelectSearch
                searchKey="faultName"
                labelKey="title"
                :searchFn="getFaultList"
                :searchParams="{
                  workOrderType: orders[$index].workOrderType || ''
                }"
                :value.sync="orders[$index].faultName"
                @change="data => (orders[$index].faultId = data.id)"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>备用电话</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <el-input
                v-model="orders[$index].readyPhone"
                maxlength="20"
                type="text"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <!-- <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>客户工单号</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <el-input
                v-model="orders[$index].customOrderNum"
                maxlength="20"
                type="text"
              />
            </el-form-item>
          </template>
        </el-table-column> -->
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>联系人</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <el-input
                v-model="orders[$index].linkedHuman"
                maxlength="20"
                type="text"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>联系电话</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <el-input
                v-model="orders[$index].linkedHumanPhone"
                maxlength="20"
                type="text"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>过保时间</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="" :rules="rules.outServiceDate">
              <el-input
                v-model="orders[$index].outServiceDate"
                maxlength="20"
                type="text"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <!-- <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>保修期</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <el-input
                v-model="orders[$index].servicePeriod"
                maxlength="20"
                type="number"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>出货商呢称</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <el-input
                v-model="orders[$index].agentNickname"
                maxlength="20"
                type="text"
              />
            </el-form-item>
          </template>
        </el-table-column> -->
        <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>项目</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <SelectSearch
                searchKey="name"
                labelKey="name"
                :searchFn="getProjectList2"
                :value.sync="orders[$index].projectName"
                @change="data => (orders[$index].projectId = data.id)"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="120">
          <template slot="header">
            <div>图片</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <CuttingUpload
                v-if="visibile"
                :showTips="false"
                :cutting="false"
                oldUpload
                v-model="orders[$index].image"
                :fileSize="1024 * 1024 * 5"
                @selectImg="data => (orders[$index].urls = data[0].id)"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="250">
          <template slot="header">
            <div>附件</div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <UploadFile
                ref="UploadFile2"
                v-if="visibile"
                oldUpload
                v-model="orders[$index].enclosureFileList"
                :showAccept="false"
                :fileList="orders[$index].enclosureFileList"
                :preAppendixAddr="
                  orders[$index] && orders[$index].enclosureUrls
                "
                :limitFileNameLength="0"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="200">
          <template slot="header">
            <div>
              <span class="red-star">*</span>
              故障描述
            </div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="" :rules="rules.description">
              <el-input
                v-model="orders[$index].description"
                type="textarea"
                maxlength="500"
                show-word-limit
              />
            </el-form-item>
          </template>
        </el-table-column>
        <!-- <el-table-column header-align="center" width="180">
          <template slot="header">
            <div>
              <span class="red-star"></span>
              延期生效
            </div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="" :rules="rules.effectTime">
              <el-switch
                v-model="!!orders[$index].effectTime"
                active-text="开"
                :active-value="true"
                :inactive-value="false"
                inactive-text="关"
              ></el-switch>
            </el-form-item>
          </template>
        </el-table-column> -->
        <el-table-column header-align="center" width="220">
          <template slot="header" slot-scope="{ row, $index }">
            <div>
              <!-- <span class="red-star">*</span> -->
              生效时间
            </div>
          </template>
          <template slot-scope="{ row, $index }">
            <el-form-item label="" :prop="'orders.' + $index + '.code'">
              <el-date-picker
                type="datetime"
                style="width: 100%"
                v-model="orders[$index].effectTime"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :picker-options="{
                  disabledDate(time) {
                    return time.getTime() < Date.now()
                  }
                }"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="90">
          <template slot-scope="{ row, $index }">
            <el-form-item label="">
              <i
                v-if="$index > 0"
                style="color: #f56c6c; font-size: 24px; margin-right: 10px"
                class="el-icon-delete"
                @click="del($index)"
              ></i>
              <el-tooltip
                class="item"
                effect="dark"
                content="复制本行数据"
                placement="right"
              >
                <i
                  style="font-size: 24px"
                  class="el-icon-circle-plus-outline"
                  @click="copyRow($index, { ...row })"
                ></i>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div style="margin-top: 10px; text-align: center">
      <el-button style="width: 48%" size="small" @click="addRow">
        添加一行
      </el-button>
      <el-button type="primary" style="width: 48%" size="small" @click="save">
        保 存
      </el-button>
    </div>
  </Page>
</template>

<script>
import {
  getBranchOfficeList,
  getStoreNameList,
  getUserList,
  getFaultList,
  getProjectList2,
  getFianSn
} from '@/api/workOrder.js'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      getBranchOfficeList,
      getStoreNameList,
      getUserList,
      getFaultList,
      getProjectList2,
      getFianSn,
      orders: [
        {
          effectTime: '',
          linkedHuman: '',
          linkedHumanPhone: ''
          // image: '',
          // description: '',
          // enclosureUrls: ''
        }
      ],
      rules: {
        branchOfficeName: [{ required: true, message: '请选择分公司' }],
        storeName: [{ required: true, message: '请选择门店' }],
        userName: [{ required: true, message: '请选择报障人' }],
        description: [{ required: true, message: '请选择故障描述' }]
      },
      loading: false,
      visibile: true
      // orders: [
      //   {
      //     effectTime: '',
      //     linkedHuman: '冯思耘',
      //     linkedHumanPhone: '13976011215',
      //     codeRequired: '0',
      //     branchOfficeName: '华润万家海南分公司',
      //     branchOfficeId: 208,
      //     storeName: '海口世纪海岸店（旺佳旺）',
      //     storeId: 11587,
      //     storeCode: '575791',
      //     address: '海口市美兰区世纪大道3号',
      //     image: '/other/33.png',
      //     urls: 74356,
      //     // enclosureUrls: 'ilgIDGKyDVeAI-chAA65qSGonWA000.png',
      //     enclosureFileList: [
      //       {
      //         name: 'ilgIDGKyDVeAI-chAA65qSGonWA000.png',
      //         url: '/other/ilgIDGKyDVeAI-chAA65qSGonWA000.png',
      //         type: 0,
      //         uid: 1699251582564,
      //         id: 74357
      //       }
      //     ]
      //   },
      // ]
    }
  },
  created() {},
  computed: {
    ...mapState(['templateData'])
  },
  watch: {
    templateData(newV, oldV) {
      this.orders = this.orders.map(item => {
        item.description = newV?.description || ''
        item.image = newV?.image || ''
        item.urls = newV?.image || ''
        item.enclosureFileList = newV?.attachment || ''
        item.enclosureUrls = newV?.attachment || ''
        return item
      })
      if (!newV.name) {
        this.visibile = false
        this.$message.success('已取消使用模版')
        setTimeout(() => {
          // 重新渲染上传组件，重置组件数据
          this.visibile = true
        }, 800)
      }
    }
  },
  methods: {
    async del(index) {
      try {
        await this.$confirm('确定删除此行数据吗？', '温馨提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.orders.splice(index, 1)
        this.$message.success('删除成功')
      } catch (e) {
        console.log(e)
      }
    },
    addRow() {
      if (this.templateData.name) {
        this.orders.push({
          effectTime: '',
          linkedHuman: '',
          linkedHumanPhone: '',
          description: this.templateData?.description || '',
          image: this.templateData?.image || '',
          urls: this.templateData?.image || '',
          enclosureFileList: this.templateData?.attachment || '',
          enclosureUrls: this.templateData?.attachment || ''
        })
      } else {
        this.orders.push({
          effectTime: '',
          linkedHuman: '',
          linkedHumanPhone: ''
        })
      }
    },
    copyRow(index, row) {
      let data = { ...row }
      this.orders.splice(index, 0, data)
      this.$message.success('复制成功')
    },
    async save() {
      try {
        // console.log(this.orders)
        console.log(111, this.orders)
        let orders = this.$utils.deepClone(this.orders),
          len = orders.length

        for (let i = 0; i < len; i++) {
          console.log(orders[i], !orders[i].sn)
          if (orders[i].workOrderType == 0 && !orders[i].code) {
            return this.$message.warning(
              `表单第 “${
                i + 1
              }” 行数据 “报障类型”为“售后”，要必填序列号，否则无法报障。`
            )
          }
          if (
            !orders[i].branchOfficeName ||
            !orders[i].storeName ||
            !orders[i].userName ||
            !orders[i].workOrderType ||
            !orders[i].description
          ) {
            return this.$message.warning(
              `表单第 “${i + 1}” 行数据存在必填项未填！`
            )
          }
          console.log('附件', orders[i].enclosureFileList)

          if (
            orders[i].enclosureFileList &&
            orders[i].enclosureFileList.length
          ) {
            orders[i].enclosureUrls = orders[i].enclosureFileList[0].id
            orders[i].enclosureFileList = undefined
          }
        }
        this.loading = true
        await this.$api.workOrder.orderListAdd(orders)
        this.visibile = false
        this.$message.success('保存成功')
        setTimeout(() => {
          this.orders = [
            { effectTime: '', linkedHuman: '', linkedHumanPhone: '' }
          ]
          // 重新渲染上传组件，重置组件数据
          this.visibile = true
        }, 800)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    //分公司改变
    branchOfficeChange(data, index) {
      this.$set(this.orders[index], 'branchOfficeId', data.id)
      this.$set(this.orders[index], 'storeName', '')
      this.$set(this.orders[index], 'storeId', '')
      this.$set(this.orders[index], 'linkedHuman', '')
      this.$set(this.orders[index], 'linkedHumanPhone', '')
      this.$set(this.orders[index], 'storeCode', '')
      this.$set(this.orders[index], 'address', '')
      const $SelectSearchStore = this.$refs[`SelectSearchStore${index}`]
      $SelectSearchStore?.setData('list', [])
    },
    //门店改变
    async storeChange(data, index) {
      try {
        let storeId = data.id
        this.$set(this.orders[index], 'storeId', storeId)
        //回显分公司
        if (!this.orders[index].branchOfficeId) {
          this.$set(this.orders[index], 'branchOfficeName', data.branch)
          this.$set(this.orders[index], 'branchOfficeId', data.branchOffice)
        }
        if (storeId) {
          let res = await this.$api.workOrder.getInfoByStoreId({ storeId })
          this.$set(this.orders[index], 'linkedHuman', res.person || '')
          this.$set(this.orders[index], 'linkedHumanPhone', res.phone || '')
          this.$set(this.orders[index], 'storeCode', res.code || '')
          this.$set(this.orders[index], 'address', res.address || '')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async cancel() {
      try {
        await this.$confirm(
          '确定取消使用模板吗？确定后将清空当前表单图片、附件、故障说明',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
        this.$store.commit('updateTemplateData', {})
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 0px;
}
.red-star {
  color: #f56c6c;
}
/deep/ .el-table__body-wrapper {
  min-height: 250px;
}
</style>
